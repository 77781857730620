<template>
  <div class="integration-step">
    <div class="step-description">
      <div class="sub-step">
        <div class="sub-step-title">
          Step 2:
        </div>
        <ol>
          <li>
            Fill in the following details:
            <br>
            First Name: <b>Asolytics</b>
            <br>
            Last Name: <b>Connect</b>
            <br>
            <div class="email-block">
              <div>
                Email: <span>{{ email }}</span>
              </div>
              <table-actions-tooltip class="copy-button">
                <template v-slot:tooltip-icon>
                  <svg-icon @click="copyEmail" icon="copy"></svg-icon>
                </template>
                <template v-slot:tooltip-text>
                  Copy
                </template>
              </table-actions-tooltip>
              <div class="copied-flag" :class="{'hidden-flag': !showCopyActiveFlag}">
                Copied!
              </div>
            </div>
          </li>
          <li>
            Check the boxes for full access integration:
            <br>
            ✅ Marketing
            <br>
            ✅ Finance
            <br>
            ✅ Customer Support
          </li>
          <li>
            Click the <b>"Next"</b> button
          </li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/email-2.png" width="540">
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import {copyToClipboard} from "@/utils/utils";

export default {
  name: 'AppStoreConnectViaEmailStep2',
  components: {
    TableActionsTooltip,
    SvgIcon,
  },
  data() {
    return {
      email: 'service@asolytics.tools',
      showCopyActiveFlag: false,
    }
  },
  methods: {
    copyEmail() {
      copyToClipboard(this.email);
      this.showCopyActiveFlag = true;

      setTimeout(() => {
        this.showCopyActiveFlag = false;
      }, 2000);
    },
  },
}
</script>
<style lang="scss" scoped>
.integration-step {
  .email-block {
    display: flex;

    span, .copy-button {
      color: var(--primary-default);
      font-weight: 600;
    }

    .copy-button {
      margin: 0 4px;
      cursor: pointer;
    }

    .copied-flag {
      transition: opacity 0.7s ease;

      &.hidden-flag {
        opacity: 0;
      }
    }
  }
}
</style>