<template>
  <div class="integration-step">
    <div class="step-description step-3">
      <div class="sub-step">
        <div class="sub-step-title">
          Step 3:
        </div>
        <div class="mb-12">
          Please confirm that you have successfully invited
          <div class="email-block">
            <span class="email">{{ email }}</span>
            <table-actions-tooltip class="copy-button">
              <template v-slot:tooltip-icon>
                <svg-icon @click="copyEmail" icon="copy"></svg-icon>
              </template>
              <template v-slot:tooltip-text>
                Copy
              </template>
            </table-actions-tooltip>
            <div class="copied-flag" :class="{'hidden-flag': !showCopyActiveFlag}">
              Copied!
            </div>
          </div>
          by checking the box below:
        </div>
        <div class="checkboxes-block type-2 mb-16">
          <input type="checkbox"
                 id="confirmation-checkbox"
                 v-model="checkbox">
          <label for="confirmation-checkbox">
            I confirm that I have invited
          </label>
        </div>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/email-2.png" width="540">
    </div>
  </div>
</template>
<script>
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {copyToClipboard} from "@/utils/utils";

export default {
  name: 'AppStoreConnectViaEmailStep3',
  components: {SvgIcon, TableActionsTooltip},
  data() {
    return {
      checkbox: false,
      email: 'service@asolytics.tools',
      showCopyActiveFlag: false,
    };
  },
  methods: {
    copyEmail() {
      copyToClipboard(this.email);
      this.showCopyActiveFlag = true;

      setTimeout(() => {
        this.showCopyActiveFlag = false;
      }, 2000);
    },
  },
  watch: {
    checkbox() {
      this.$emit('confirmation-changed', this.checkbox);
    },
  },
}
</script>
<style lang="scss" scoped>
.step-3 {
  label {
    font-size: 16px;
    color: var(--neutral-800);
    padding-left: 24px;

    &:before, &:after {
      top: 4px;
    }
  }

  .email {
    color: var(--primary-default);
    font-weight: 600;
  }

  .email-block {
    display: flex;

    span, .copy-button {
      color: var(--primary-default);
    }

    .copy-button {
      margin: 0 4px;
      cursor: pointer;
    }

    .copied-flag {
      transition: opacity 0.7s ease;

      &.hidden-flag {
        opacity: 0;
      }
    }
  }
}
</style>
