<template>
  <div class="profile-settings-wrap-new integration-settings">
    <div class="common-white-container settings-container">
      <div class="back-link">
        <router-link :to="{name: 'IntegrationSettingsList'}">
          <svg-icon icon="arrow-left" />
          Back to App list
        </router-link>

        <base-button v-if="!isFinished"
                     height="36px"
                     width="95px"
                     @click="cancel"
                     mode="outline"
                     font-size="14px"
                     border-radius="4px">
          <span>Cancel</span>
        </base-button>
      </div>

      <div class="page-title">
        <div>
          App Store Account Connecting
        </div>
        <div class="steps-counter" v-if="!isFinished">
          {{ currentStep }}/{{ stepsAmount }}
        </div>
      </div>

      <integration-loading-step v-if="isFinished" />
      <div v-else class="integration-step-wrap">
        <component :is="currentStepComponent" @confirmationChanged="confirmationChanged" />

        <div class="buttons">
          <base-button v-if="currentStep > 1"
                       height="40px"
                       width="160px"
                       border-radius="4px"
                       @click="currentStep--"
                       mode="outline">
            <span>Back</span>
          </base-button>

          <base-button v-if="currentStep < stepsAmount"
                       height="40px"
                       width="160px"
                       @click="currentStep++"
                       border-radius="4px">
            <span>Next</span>
          </base-button>

          <base-button v-if="currentStep === stepsAmount"
                       :disabled="!isIntegrationEnabled"
                       height="40px"
                       width="200px"
                       @click="submit"
                       border-radius="4px">
            <span>Add Integration</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppStoreConnectViaEmailStep1 from '@/views/Account/IntegrationSettings/AppStore/ViaEmail/Steps/AppStoreConnectViaEmailStep1.vue';
import AppStoreConnectViaEmailStep2 from '@/views/Account/IntegrationSettings/AppStore/ViaEmail/Steps/AppStoreConnectViaEmailStep2.vue';
import AppStoreConnectViaEmailStep3 from '@/views/Account/IntegrationSettings/AppStore/ViaEmail/Steps/AppStoreConnectViaEmailStep3.vue';
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {httpRequest} from "@/api";
import IntegrationLoadingStep from "@/views/Account/IntegrationSettings/AppStore/ViaEmail/Steps/Loading.vue";

export default {
  name: 'AppStoreConnectViaEmail',
  components: {
    IntegrationLoadingStep,
    SvgIcon,
    'step-1': AppStoreConnectViaEmailStep1,
    'step-2': AppStoreConnectViaEmailStep2,
    'step-3': AppStoreConnectViaEmailStep3,
  },
  data() {
    return {
      currentStep: 1,
      stepsAmount: 3,
      isIntegrationEnabled: false,
      isFinished: false,
    };
  },
  methods: {
    async submit() {
      this.isIntegrationEnabled = false;
      let formData = new FormData();
      formData.append('app_id', this.$route.params.app_id);
      const result = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_SHARE_EMAIL,
        formData
      );
      if (result.result) {
        this.isFinished = true;
      }
      this.isIntegrationEnabled = true;
    },
    cancel() {
      this.$router.push({name: 'IntegrationSettingsList'});
    },
    confirmationChanged(value) {
      this.isIntegrationEnabled = value;
    }
  },
  computed: {
    currentStepComponent() {
      return 'step-' + this.currentStep;
    },
  },
}
</script>

<style src="./../../../styles.scss" lang="scss"></style>
<style src="./../../styles.scss" lang="scss"></style>
