<template>
  <div class="integration-step">
    <div class="step-description">
      <div class="step-1-disclaimer">
        <div class="mb-16">🕒️ <i>The integration setup process typically spans 1 business day.</i></div>
        <div>☝️ <i>Make sure you have <b>Admin account</b> in App Store Connect.</i></div>
      </div>

      <div class="sub-step">
        <div class="sub-step-title">
          Step 1:
        </div>
        <ol>
          <li>Go to <a target="_blank" href="https://appstoreconnect.apple.com/access/users">Users and Access</a> in App Store Connect.</li>
          <li>Click the <b>"+ Add"</b> button to proceed.</li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/email-1.png" width="540">
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppStoreConnectViaEmailStep1',
}
</script>
<style lang="scss" scoped>
.step-1-disclaimer {
  background: var(--neutral-100);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.sub-step a {
  color: var(--primary-default);
  font-weight: 600;

  &:hover {
    text-transform: unset;
  }
}
</style>
