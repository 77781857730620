<template>
  <div class="integration-loading-step">
    <div class="title">
      Integration in Progress
    </div>
    <div class="first-description">
      The integration setup process typically spans 1 business day. We appreciate your patience during this integration period.
    </div>
    <progress-indicator />
    <div class="last-description">
      For any inquiries or assistance, please feel free to <span class="contact">contact our support team</span>. We appreciate your patience during this integration period.
    </div>
  </div>
</template>
<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";

export default {
  name: 'IntegrationLoadingStep',
  components: {ProgressIndicator},
}
</script>
<style lang="scss" scoped>
.integration-loading-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 70px;
  line-height: 24px;

  .loading-indicator {
    position: relative;
    transform: none;
    top: unset;
    left: unset;
  }

  div {
    max-width: 580px;
    text-align: center;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-900);
    margin-bottom: 12px;
  }

  .first-description {
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-800);
  }

  .last-description {
    color: var(--neutral-700);

    .contact {
      color: var(--primary-default);
      cursor: pointer;
    }
  }
}
</style>
